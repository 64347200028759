<template>
    <form class="form" @submit.stop.prevent="submit" ref="form">
        <label class="label-calm mb-4">
            Name
            <input ref="nameInput"
                   id="edit-collection-modal--name"
                   class="form-control"
                   @keypress.enter.prevent="submit"
                   v-model="value.name"
                   placeholder="Enter a Collection name"/>
        </label>

        <label class="mb-4">
            <span class="label-calm ">Icon</span>
            <icon-picker :icons="icons" v-model="value.icon"></icon-picker>
        </label>

        <div v-if="accountFeatures.collectionSmartScore">
            <label class="mb-4 form-label label-calm">
                <span class="d-flex align-items-start gap-1">
                    <span class="mt-2">Purpose</span>
                    <help-button html :content="helpText.purpose" :help-doc-link="learnUrls.collectionPurpose"  class="pt-0 mt-0 mb-0 pb-0"></help-button>
                </span>
                <select v-model="value.purpose" class="form-select" required>
                    <option value="internal">Workflow Step</option>
                    <option value="outbound">Outbound Destination</option>
                </select>
            </label>
            <labeler-with-addons>
                <template v-slot:label>
                    <span class="d-flex align-items-start gap-1">
                        <span class="mt-2">Predict Performance Based On</span>
                        <help-button html :content="helpText.smartSortBasis" :help-doc-link="learnUrls.smartSortBasis" class="pt-0 mt-0 mb-0 pb-0"></help-button>
                    </span>
                </template>
                <template v-slot:label-right>
                    <tiny-toggle
                        :options="value.purpose === 'outbound' ? ['Self', 'Account', 'Collection'] : ['Account', 'Collection']"
                        v-model="value.smartSortBasisCategory"/>
                </template>
            </labeler-with-addons>
            <div>
                <collection-topic-selector
                    v-if="value.smartSortBasisCategory === 'Collection'"
                    :exclude="collectionId"
                    :required="value.smartSortBasisCategory === 'Collection'"
                    v-model="value.smartSortBasisCollection"
                    :cross-account="false"
                    type="collection"
                    class="mb-4"
                    purpose="outbound"/>
                <output class="form-control bg-light-primary mb-4" disabled v-else-if="value.smartSortBasisCategory === 'Account'">Account: {{currentAccountOverview.name}}</output>
                <output class="form-control  bg-light-primary mb-4" disabled v-else-if="value.smartSortBasisCategory === 'Self'">This Collection: {{value.name}}</output>
            </div>
        </div>


        <label class="label-calm mb-4">
            Folder
            <select class="form-control form-select" v-model="folderId" required placeholder="Select a Folder">
                <option :value="rootFolderId">No Folder (Private)</option>
                <option v-for="availableFolder in availableFolders"
                        :key="'query-builder-folder-'+availableFolder.id"
                        :value="availableFolder.id">
                    {{ availableFolder.name }}
                </option>
            </select>
        </label>

        <label class="label-calm mb-4">
            Description
            <input type="text"
                   class="form-control"
                   v-model="value.description"
                   @keypress.enter.prevent="submit"
                   placeholder="Enter a Collection description (optional)"/>
        </label>

        <ul class="errors"  v-if="errorMessages && errorMessages.length">
            <li v-for="error in errorMessages" class="error" :key="error">{{ error }}</li>
        </ul>

        <div class="alert alert-warning"  v-if="networkError" v-html="networkError">

        </div>

    </form>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import IconPicker from '@/components/Controls/IconPicker'
import {getIconDescription, iconOptions} from "@/services/Icons"
import * as toastr from "toastr";
import {getIconClass} from "@/services/Icons";
import HelpButton from "@/components/Controls/HelpButton.vue";
import purposeHelp from '@/content/help/collections/purpose.md';
import smartSortBasisHelp from '@/content/help/collections/smartSortBasis.md';
import CollectionTopicSelector from "@/components/Controls/CollectionTopicSelector.vue";
import TinyToggle from "@/components/Controls/TinyToggle.vue";
import LabelerWithAddons from "@/components/Controls/LabelerWithAddons.vue";


async function updateCollectionFolder(value, account, folderId) {
    console.log('updateCollectionFolder', value, account, folderId)
    let url = '/collections/' + value.id + '/parent';
    if (!value.account && value.account.includes(account.id)) {
        url = '/collections/' + value.id + '/account';
    }
    try {
        return await window.$app.api.put(url, {folder: folderId, account: account.id})
    } catch (e) {
        throw {e, url}
    }
}

function createCollection(accountId, collectionObject) {
    return window.$app.api.post('/accounts/' + accountId + '/collections', collectionObject);
}

export default {
    props: {
        collectionId: String,
        enableViewEditMode: {type: Boolean, default: false},
    },
    components: {
        LabelerWithAddons,
        TinyToggle,
        CollectionTopicSelector,
        HelpButton,
        IconPicker
    },
    computed: {
        ...mapGetters({
            currentAccountOverview: 'currentAccountOverview',
            folders: 'folders',
            collections: 'collections',
            accountFeatures: 'accountFeatures',
            preferences: 'preferences',
            learnUrls: 'learnUrls',
            canManageCollection: 'canManageCollection',
        }),
        smartSortIsNotCollection() {
            return this.value.smartSortBasisCategory !== 'Collection'
        },
        smartSortBasisCategoryOptions() {
            return this.value.purpose === 'outbound' ? ['Self', 'Account', 'Collection'] : ['Account', 'Collection'];
        },
        icons: () => iconOptions,
        iconClass() { return getIconClass(this.value.icon||''); },
        iconDescription() { return getIconDescription(this.value.icon||''); },
        availableFolders() {
            return this.folders.filter(
                f => f.account && f.account.includes(this.currentAccountOverview.id) && !this.currentAccountOverview.root.includes(f.id));
        },
        rootFolderId() {
            if (this.currentAccountOverview && this.currentAccountOverview.root) {
                let rootFolder = this.folders.filter(f => f).find(f => this.currentAccountOverview.root.includes(f.id));
                if (rootFolder) {
                    return rootFolder.id
                }
            }
            return null;
        },
        collection() {
            return this.collections.find(c => c.id.includes(this.collectionId));
        },
        folder() {
            return this.availableFolders.find(f => this.value.folder.includes(f.id)) || {
                id: this.rootFolderId
            };
        },
    },
    watch: {
        collection() {
            this.loadCollectionValue();
        },
        networkActivity() {
            this.$emit('networkActivity', this.networkActivity)
        },
        rootFolderId() {
            this.folderId = this.rootFolderId
        }
    },
    data() {
        return {
            resetSignal: 0,
            errorMessages: [],
            value: {},
            networkActivity: false,
            folderId: this.rootFolderId,
            networkError: false,
            helpText: {
                purpose: purposeHelp,
                smartSortBasis: smartSortBasisHelp,
            }
        }
    },
    methods: {
        ...mapActions(['setCurrentCollection', 'addCollection', 'moveCollectionsToFolder']),
        loadCollectionValue() {
            if (this.collectionId && this.collection) {
                this.value = {...this.collection};
                this.value.folder = this.collection.folder || this.rootFolderId;
                this.folderId = this.folder.id || this.rootFolderId;
            } else {
                this.value = {
                    id: null,
                    name: '',
                    description: '',
                    smartSortBasisCategory: 'Account',
                    smartSortBasisCollection: null,
                    purpose: null,
                    folder: this.rootFolderId,
                    icon: 'bolt'
                }
                this.folderId = this.folder.id;
            }

            this.errorMessages = [];
        },
        async saveCollection() {
            console.log('saveCollection', this.folder, this.value, this.folderId)
            this.networkError = false;
            this.validate();
            if (this.errorMessages.length) return;

            this.networkActivity = true;

            await this.updateCollection(this.collection.id, this.value).catch(e => this.flagNetworkError(e, [this.collection.id, this.value]));

            if (this.networkError) {
                this.networkActivity = false;
                return;
            }
            /* Moving a collection to the root isn't supported unless an else condition is added here. */
            if (this.folderId) {
                await this.saveFolderChange();
            }

            try {
                await this.$store.dispatch('addCollection', {collection: this.value})
            } catch (e) {
                this.flagStoreError(e, ['addCollection', {collection: this.value}])
            }

            // Save successful
            this.loadCollectionValue();
            this.clearNetworkActivity(1000);

            this.$emit("saved");

            toastr.success(`Your changes to the collection '${this.collection.name}' have been saved!`, 'Saved');
        },
        validate() {
            this.errorMessages = [];
            if (!this.value.name) this.errorMessages.push("Must set a collection name")
        },
        async saveFolderChange() {
            console.log('saveFolderChange', this.folder, this.value, this.folderId)
            if (!this.value.folder.includes(this.folderId)) {
                this.networkError = false;
                let collections = [{...this.value, folder: this.folderId}];

                await updateCollectionFolder(this.value, this.currentAccountOverview, this.folderId).catch(({url, e}) => this.flagNetworkError(e, [
                    url,
                    {folder: this.folderId, account: this.currentAccountOverview.id}
                ]));

                if (this.networkError) {
                    this.networkActivity = false;
                    return;
                }

                try {
                    await this.$store.dispatch('moveCollectionsToFolder', {collections, folder: {id: this.folderId}})
                } catch (e) {
                    this.flagStoreError(e, ['moveCollectionsToFolder', collections, {id: this.folderId}])
                }
            }
        },
        flagNetworkError(e, info) {
            this.networkError = true;
            console.error('Network Error', info, e);
            let message = `<p>An error was detected that prevented this collection from being saved.</p>
                           <p>Check your network connection and try again.  If this error persist, please
                           contact support.</p>`;
            toastr.error(message, 'Network Error');

            this.clearNetworkActivity();

        },
        flagStoreError(e, info) {
            this.networkError = true;
            console.error('Application Error', info, e);
            let message = `<p>An error was detected that prevented this collection from being saved.</p>
                           <p>Refresh and try again.  If this error persist, please
                           contact support.</p>`;
            toastr.error(message, 'Application Error');

            this.clearNetworkActivity();

        },
        clearNetworkActivity(amt = 3000) {
            setTimeout(() => {
                this.networkActivity = false
            }, amt);
        },
        async createCollectionToServer() {
            this.networkActivity = "Creating collection..."
            let description = this.value.description ? this.value.description : "";

            let collectionObject = {
                folder: this.folderId,
                name: this.value.name,
                icon: this.value.icon,
                description
            }

            try {
                let response = await createCollection(this.currentAccountOverview.id, collectionObject);
                let collection = {...response.data};

                this.addCollection({collection});

                await this.setCurrentCollection({collection});
                this.networkActivity = false;
                return collection;
            } catch (e) {
                if (e.response && e.response.data) {
                    this.networkActivity = false;
                    this.networkError = e.response.data.description
                    if (e.response.data.title.includes('exceeded')) {
                        this.networkError =
                            `You need to <a href="/settings#subscription" target="_blank"><b>upgrade your subscription plan</b></a> to add any more collections`;
                    }
                    this.$emit('error', this.networkError)
                }
                return Promise.reject(e)
            }
        },
        async createCollection() {
            this.validate();
            if (this.errorMessages.length) return;
            let collection = await this.createCollectionToServer();

            this.$emit("saved");
            toastr.success(`Collection '${this.value.name}' created!`, 'Saved');
            return collection;
        },
        submit() {
            console.log('collectionModal.submit', this.collectionId)
            if (!this.$refs.form.checkValidity()) return;
            if (this.collectionId) {
                this.saveCollection()
            } else {
                this.createCollection()
            }
        },
        updateCollection(id, value) {
            console.log('* updateCollection value', value)
            let smartScoreBasedOn = '';
            if (value.smartSortBasisCategory === 'Account') {
                smartScoreBasedOn = value.account.slice(-36);
            } else if (value.smartSortBasisCategory === 'Self') {
                smartScoreBasedOn = id;
            } else if (value.smartSortBasisCategory === 'Collection' && value.smartSortBasisCollection) {
                smartScoreBasedOn = value.smartSortBasisCollection;
            }
            return Promise.all([
                window.$app.api.put('/collections/' + id + '/name', {name: value.name}),
                window.$app.api.put('/collections/' + id + '/icon', {icon: value.icon}),
                window.$app.api.put('/collections/' + id + '/description', {description: value.description}),
                window.$app.api.put('/collections/' + id + '/outbound', {outbound: value.purpose === 'outbound'}),
                this.accountFeatures.collectionSmartScore === true ?
                    window.$app.api.put('/collections/' + id + '/smart-score-based-on', {smart_score_based_on: smartScoreBasedOn})
                    : Promise.resolve(),
            ]);
        },
    },
    mounted() {
        this.loadCollectionValue();
    },
}
</script>

<style scoped lang="scss">
.advanced-options {
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    max-height: 0;
}

.advanced-options.show {
    max-height: 1500px;
}

.auto-scroll {
    overflow: auto;
}

.height-500 {
    max-height: 300px;
}

.scroll-message-container {
    position: relative;
}

.scroll-message {
    position: absolute;
    bottom: 0;
    border-radius: 12px;
    background-color: var(--bs-primary);
    color: white;
    padding: 3px 10px;
    font-size: .8em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 200px;
    text-align: center;
}

.scroll-message:hover {
    opacity: 0;
    filter: alpha(opacity=0);
}

.errors {
    padding-left: 0;
    color: var(--bs-danger);
    display: block;
}

.error {
    list-style: none;
    color: var(--bs-danger);
    font-size: 0.9em;
}

//
//.save-button-container {
//    position: relative;
//    overflow: hidden;
//}
//.save-button {
//    position: relative;
//    opacity: .5;
//    top: 50%;
//}
//.save-button.is-editing {
//    top: 0;
//    opacity: 1;
//}
</style>
