// All values are overridden by config cat if a config cat entry exists.
// true enabled for all users
// false       disabled for all users
// ‘qa’        only in qa/dev
// ‘dev’       only in dev
// ‘upcontent‘ only upcontent emails regardless of environment.

export let features = {
    collectionIcons: true,
    sniply: true,
    rss: true,
    shortenLinks: true,
    amplify: true,
    du: false,
    collectionSources: true,
    metaIntegrations: true,
    hubspotEmail: true,
    refreshSources: true,
    upcSources: true,
    crossAccount: true,
    nativeShare: true,
    mailchimpEmail: true,
    hubspot: true,
    mailchimp: true,
    galleryBuilder: true,
    gallery_builder: true,
    editSourceSources: true,
    customSelectionImageText: true,
    shutterstock: false,
    shutterstock_editor: false,
    purchaseAddons: true,
    apiAdmin: 'dev',
    galleryUtm: 'dev',
    icebreaker: true,
    tags: true,
    experimental: 'dev',
    zestCollectionSource: false,
    imageOnlyInSuggestions: true,
    contentTemplates: true,
    otherContentType: 'upcontent',
    constant_contact: true,
    proofpoint: true,
    qualityChecks: true,
    contentSections: true,
    hubspotEmailV2: false,
    hubspotShareV2: 'partner',
    selectFilters: true,
    getty: true,
    languageInTopicSearch: true,
    newHootsuiteCompose: true,
    topicRefresh: 'dev',
    editSelection: true,
    newCollectionUx: true,
    predictedPerformance: true,
    profitwellCancellation: false,
    customSources: false,
    newPowerupsUx: false,
    newFeedUrl: true,
    actionBar: true,
    clearFilters: true,
    contentPolicy: true,
    amplifyExpiry: true,
    topicRss: false,
    newPowerupsUxCards: false,
    collectionParameterMappings: 'qa',
    textLengthFilter: true,
    advCollectionIndexSettings: 'qa',
    deactivatedCollections: 'qa',
    editAssignmentsModal: true,
    editFilterViewPageAddEdit: 'qa',
    everyone_social: 'qa',
    newFilterDropdown: 'dev',
    openaiIntegration: true,
    contentPolicyEnabled: true,
    amplifyLinks: 'qa',
    selectionProcessingUi: 'qa',
    reports: 'qa',
    collectionSmartScore: 'dev',
};
export default features;

export const featureNames = Object.keys(features);

export const subscriptionFeaturesDefaults = {
    rss: undefined,
    gallery_builder: false,
    shutterstock: false,
    shutterstock_editor: false,
    contentTemplates: false,
    qualityChecks: false,
    proofpoint: false,
    openaiIntegration: false,
    contentPolicyEnabled: false,
    getty: false
}

window.ff = featureNames.reduce((flags, flag) => ({...flags, [flag]: flag}), {})
